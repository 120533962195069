export enum SsoProvider {
  Google = 0,
  Clever = 1,
  EducationElements = 2,
  LDAP = 3,
  ActiveDirectory = 4,
  ClassLink = 5,
  JwtProvider = 6,
  GlobalGridForLearning = 7,
  IdentityServer
}
export namespace SsoProvider {
  export function toList(): SsoProvider[] {
    return Object.keys(SsoProvider).filter(option => !isNaN(Number(option))).map(option => +option);
  }
}
